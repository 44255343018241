export enum EventType {
    DOCUMENT_VISIBLE = 'document-visible'
}

export class EventBus {
    private readonly handlers: Record<string, EventHandler[]> = {};

    /**
     * Creates a new instance of the EventBus.
     */
    constructor() {}

    /**
     * Adds listener of the specified event.
     */
    public on(eventName: string, handler: EventHandler) {
        const handlersList = this.handlers[eventName] || [];

        if (this.handlers[eventName] === undefined) {
            this.handlers[eventName] = handlersList;
        }

        handlersList.push(handler);

        return this.off.bind(this, eventName, handler);
    }

    /**
     * Registers a callback that is called once when
     * the specified event is emitted.
     */
    public once(eventName: string, handler: EventHandler) {
        const wrappedHandler = async (...args: any) => {
            await handler(...args);
            this.off(eventName, wrappedHandler);
        };

        this.on(eventName, wrappedHandler);
    }

    /**
     * Removes listener of the specified event.
     */
    public off(eventName: string, handler: EventHandler) {
        const list = this.handlers[eventName];
        if (list !== undefined) {
            this.handlers[eventName] = list.filter((x) => x !== handler);
        }
    }

    /**
     * Emits event to the local tab.
     */
    public async emit(eventName: string, ...args: any[]) {
        const list = this.handlers[eventName];

        if (list !== undefined) {
            const promises = list.map((x) => x(...args));
            await Promise.all(promises);
        }
    }
}

export type EventHandler = (...args: any[]) => Promise<void> | void;

export default (nuxtApp) => {
    nuxtApp.provide('eventBus', new EventBus())
}